import React, { useState } from 'react';
import Header from '../../Header';

import styles from './PumpOrder.module.css';
import { useDispatch, useSelector } from 'react-redux';
import InputWithButtons from '../../Input/InputWithButtons/InputWithButtons';
import CheckBox from '../../../../../Features/CheckBox/CheckBox';
import { RootState } from '../../../../../../store/store';
import {
    setIsPipeRequired,
    setIsTubeRequired,
    setPumps,
} from '../../../../../../store/slices/sliceSelectedInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Trash from '../../../../../../content/svg/RequestsPage/Trash';
import DateAndTimeInput from '../../Input/DateAndTimeInput/DateAndTimeInput';
import { addDays, format } from 'date-fns';

function PumpOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const pumps = useSelector((state: RootState) => state.selectedInfo.pumps);
    const savedInfo = location?.state?.pumpInfo;
    const currentIndex = location?.state?.index;

    const lengthList = [24, 28, 32, 36, 42, 46, 48, 52, 56, 58, 62, 65];

    const [localIsTubeRequired, setLocalIsTubeRequired] = useState(
        savedInfo?.isTubeRequired || false,
    );
    const [localIsPipeRequired, setLocalIsPipeRequired] = useState(
        savedInfo?.pipeCount ? true : false,
    );
    const [localPipeCount, setLocalPipeCount] = useState(savedInfo?.pipeCount || 0);
    const [currentLength, setCurrentLength] = useState(savedInfo?.arrowLength || NaN);
    const [localHeight, setLocalHeight] = useState(savedInfo?.objectHeight || 10);
    const [localWidth, setLocalWidth] = useState(savedInfo?.objectWidth || 10);
    const [comment, setComment] = useState(savedInfo?.comment || '');

    const nasosDate = useSelector((state: RootState) => state.requests.nasosDate);

    const deletePump = () => {
        const newPumps = pumps.filter((_, index) => index !== currentIndex);
        dispatch(setPumps(newPumps));
        navigate(-1);
    };

    const saveData = () => {
        let newPumps = [];
        if (currentLength) {
            const pumpInfo = {
                objectHeight: localHeight,
                objectWidth: localWidth,
                pipeCount: localIsPipeRequired ? localPipeCount : 0,
                arrowLength: currentLength,
                isTubeRequired: localIsPipeRequired ? localIsTubeRequired : false,
                nasosDate: format(nasosDate, 'dd.MM.yyyy HH:mm'),
                commentary: comment,
            };
            if (currentIndex !== undefined) {
                newPumps = [...pumps.filter((_, index) => index !== currentIndex), pumpInfo];
            } else {
                newPumps = [...pumps, pumpInfo];
            }
            dispatch(setPumps(newPumps));
            navigate(-1);
        } else {
            toast.error('Выберите длину стрелы');
        }
    };
    return (
        <div className={styles.page}>
            <Header headerTitle="Насос под заказ" backRedirect="nasosSettings" />
            <div className={styles.content}>
                <span className="titleRequestMenu">Когда</span>
                <DateAndTimeInput
                    date={nasosDate}
                    setDate={() => {
                        return null;
                    }}
                    lower_date={new Date()}
                    upper_date={addDays(new Date(), 14)}
                />
                <span className={styles.header}>Параметры</span>
                <div className={styles.selectLength}>
                    <select
                        name=""
                        className={isNaN(currentLength) ? styles.placeholder : styles.select}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            if (event?.target.value === 'Линейный насос') {
                                setCurrentLength('Линейный насос');
                                return;
                            }
                            setCurrentLength(
                                +(event?.target as HTMLSelectElement).value.split(' ')[0],
                            );
                        }}
                        onBlur={(event) => event.preventDefault()}
                        defaultValue={isNaN(currentLength) ? 'Длина стрелы' : currentLength + ' м'}
                    >
                        <option value={'Длина стрелы'} hidden className={styles.placeholder}>
                            {'Длина стрелы'}
                        </option>
                        {lengthList.map((value, index) => (
                            <option key={index} className={styles.length} value={value}>
                                {value} м
                            </option>
                        ))}
                        <option value="Линейный насос" className={styles.length}>
                            {'Линейный насос'}
                        </option>
                    </select>
                </div>
                <div className={styles.block}>
                    <span className={styles.subHeader}>Размер вашей площадки</span>
                    <div className={styles.size}>
                        <InputWithButtons
                            inputTitle={'Длина'}
                            inputValue={localHeight}
                            setInputValue={setLocalHeight}
                            valueToChange={1}
                            maxValue={20}
                            minValue={3}
                        />
                        <InputWithButtons
                            inputTitle={'Ширина'}
                            inputValue={localWidth}
                            setInputValue={setLocalWidth}
                            valueToChange={1}
                            maxValue={20}
                            minValue={3}
                        />
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.checkbox}>
                        <span className={styles.subHeader}>Нужны трубы</span>
                        <CheckBox
                            checked={localIsPipeRequired}
                            onChange={setLocalIsPipeRequired}
                            name="isPipeRequired"
                        />
                    </div>
                    {localIsPipeRequired && (
                        <div className={styles.pipeAndTube}>
                            <div>
                                <InputWithButtons
                                    inputTitle="Трубы, шт"
                                    inputValue={localPipeCount}
                                    setInputValue={setLocalPipeCount}
                                    valueToChange={1}
                                    minValue={1}
                                />
                            </div>
                            <div className={styles.checkbox}>
                                <span className={styles.subHeader}>Нужен шланг</span>
                                <CheckBox
                                    checked={localIsTubeRequired}
                                    onChange={setLocalIsTubeRequired}
                                    name="isTubeRequired"
                                />
                            </div>
                        </div>
                    )}

                    <div className={styles.commentBlock}>
                        <p className={styles.subHeader}>Комментарий</p>

                        <textarea
                            className={styles.comment}
                            placeholder="Комментарий"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                </div>

                <div className={styles.buttons}>
                    {currentIndex || currentIndex === 0 ? (
                        <div className={styles.trash} onClick={deletePump}>
                            <Trash theme={localStorage.getItem('theme')} />
                        </div>
                    ) : null}
                    <button className={styles.saveButton} onClick={saveData}>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PumpOrder;
